import exporoURLs from '../brands/exporo/urls'
import propvestURLs from '../brands/propvest/urls'
import {URLs} from '../brands/types'

let urls: () => URLs

switch(process.env.VUE_APP_BRAND) {
case 'PROPVEST':
  urls = propvestURLs
  break
case 'EXPORO':
  urls = exporoURLs
  break
default:
  urls = propvestURLs
}

export default urls
