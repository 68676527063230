
import {Options, Prop, Vue, Watch} from 'vue-property-decorator'
import Globe from '../../../icons/Globe.vue'
import ChevronUp from '../../../icons/ChevronUp.vue'
import ChevronDown from '../../../icons/ChevronDown.vue'
import {Alignment, Locale} from './types'
import i18n from '../../../i18next/i18n'

@Options({
  components: {
    Globe,
    ChevronUp,
    ChevronDown
  }
})
export default class LocaleSwitch extends Vue {
  @Prop({ default: Locale.DE }) locale!: Locale
  @Prop() onClick?: (value: Locale) => void
  @Prop({ default: Alignment.TOP }) alignment!: Alignment

  active: boolean = false

  @Watch('locale', { immediate: true })
  handleLocaleChange(value: Locale) {
    i18n.locale.value = value
  }

  get innerLocale() {
    return i18n.locale.value
  }

  get options(): { key: string, value: Locale }[] {
    return [
      { key: 'ENG', value: Locale.ENG },
      { key: 'DE', value: Locale.DE }
    ]
  }

  handleClick(value: Locale) {
    i18n.locale.value = value
    this.switchOptions()
    if (this.onClick) {
      this.onClick(value)
    }
  }

  switchOptions() {
    this.active = !this.active
  }
}
