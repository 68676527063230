import {ref} from 'vue'
import BrandedComponentLibrary from '../../urls/BrandedComponentLibrary'
import {URLs} from '../types'

const signUpLink = (): string => isStage()
  ? 'https://registration-stage.propvest.de/signup/'
  : 'https://registration.propvest.de/signup/'

const signInLink = (): string => isStage()
  ? `https://dev.user.propvest.de/master/#/signin?redirectURL=${window.location.href}`
  : `https://user.propvest.de/#/signin?redirectURL=${window.location.href}`

const userMeta = (): string => isStage()
  ? 'https://api.user.propvest.de/stage/user/metaData'
  : 'https://api.user.propvest.de/prod/user/metaData'

const userServiceBaseUrl = (): string => isStage()
  ? 'https://dev.user.propvest.de/master'
  : 'https://user.propvest.de'

const userServiceAPIUrl = (): string => isStage()
  ? 'https://api.user.propvest.de/stage'
  : 'https://api.user.propvest.de/prod'


const userPoolID = (): string => isStage()
  ? 'eu-central-1_Um8D678vf'
  : 'eu-central-1_l7h8zENgx'

const userPoolClientID = (): string => isStage()
  ? '7jf5n6cg6l7q9e4rssbb23lbuo'
  : '667fgdio3991qiv8c51ahgr76'

const homeUrl = (): string => isStage()
  ? 'https://stage.app.propvest.de/'
  : 'https://www.propvest.de/'

const accountUrl = (): string => isStage()
  ? 'https://dev.user.propvest.de/master/#/profile'
  : 'https://user.propvest.de/#/profile'

const documentsUrl = (): string => isStage()
  ? 'https://stage.app.propvest.de/documents'
  : 'https://cockpit.propvest.de/documents'

const dashboardUrl = (): string => isStage()
  ? 'https://stage-dashboard.propvest.de/'
  : 'https://dashboard.propvest.de/'

const cockpitUrl = (): string => isStage()
  ? 'https://stage.app.propvest.de/portfolio'
  : 'https://cockpit.propvest.de/portfolio'

const onboardingUrl = (): string => isStage()
  ? 'https://dev.user.propvest.de/master/#/onboarding'
  : 'https://user.propvest.de/#/onboarding'

const explorationUrl = (): string => isStage()
  ? 'https://exploration-stage.propvest.de/survey/wphgVermittlungSTO'
  : 'https://exploration.propvest.de/survey/wphgVerwaltungSTO'

const secondaryMarketBaseUrl = (): string => isStage()
  ? 'https://stage.marketplace.propvest.de'
  : 'https://marketplace.propvest.de'

const checkoutBaseUrl = (): string => isStage()
  ? 'https://checkout-stage.propvest.de'
  : 'https://checkout.propvest.de'

const pdpBaseUrl = (): string => isStage()
  ? 'https://pdp-stage.propvest.de'
  : 'https://pdp.propvest.de'

const tradingPlatformBaseUrl = (): string => isStage()
  ? 'https://trade-stage.propvest.de'
  : 'https://trade.propvest.de'

const baaderBankBaseUrl = (): string => isStage()
  ? 'https://dev.baader-bank.propvest.de/master/#/'
  : 'https://baader-bank.propvest.de'

const isStage = () => ref(BrandedComponentLibrary.getInstance().getEnv().value !== 'PROD').value

export default (): URLs => {
  return {
    oneLoginClientId: 'ce8ab490-d9b1-013a-8dfc-025362659ebe37836',
    explorationURL: explorationUrl(),
    onboardingURL: onboardingUrl(),
    secondaryMarketBaseURL: secondaryMarketBaseUrl(),
    checkoutBaseURL: checkoutBaseUrl(),
    pdpBaseUrl: pdpBaseUrl(),
    home: homeUrl(),
    dashboard: dashboardUrl(),
    cockpit: cockpitUrl(),
    account: accountUrl(),
    documents: documentsUrl(),
    imprint: 'https://www.propvest.de/impressum',
    legal: 'https://www.propvest.de/legal-notice',
    dataPrivacy: 'https://www.propvest.de/datenschutz',
    signUpLink: signUpLink(),
    signInLink: signInLink(),
    tradingPlatformBaseUrl: tradingPlatformBaseUrl(),
    userMeta: userMeta(),
    userServiceBaseUrl: userServiceBaseUrl(),
    userServiceAPIUrl: userServiceAPIUrl(),
    userPoolID: userPoolID(),
    userPoolClientID: userPoolClientID(),
    baaderBank: baaderBankBaseUrl(),
    baaderBankAPIUrl: 'https://baader.exporo.de/api/user/data/current-user'
  }
}


