import BrandedComponentLibrary from '../urls/BrandedComponentLibrary'

type props = { [index: string]: string | [] | object | boolean }

const isJsonString = (input: string) => {
  try {
    JSON.parse(input)
  } catch (e) {
    return false
  }
  return true
}

const isFunction = (type: any): boolean => (typeof type === 'function')

const getPropsFromAttributes = (componentAttributes: NamedNodeMap): props => {
  const props: props = {}

  for (const attributes of Array.from(componentAttributes)) {
    const decodedURI = decodeURI(attributes.value)

    if (isJsonString(decodedURI)) {
      props[attributes.name] = JSON.parse(decodedURI)
    } else if (['true', 'false'].includes(attributes.value))  {
      props[attributes.name] = Boolean(attributes.value)
    } else {
      props[attributes.name] = attributes.value
    }
  }

  return props
}

const setEnvFromAttributes = (htmlElement: HTMLElement) => {
  const environmentAttribute: Attr | null = htmlElement.attributes.getNamedItem('env')
  BrandedComponentLibrary.getInstance().setEnv(environmentAttribute?.value === 'dev' ? 'DEV' : 'PROD')
}

const transformValueFromAttribute = (value: unknown) => {
  if(typeof value === 'object') {
    return encodeURI(JSON.stringify(value))
  }

  return value
}

const transformKeyFromAttribute = (key: string) => {

  return key.replace(/[A-Z]/g, match => '-' + match.toLowerCase())
}

const transformToAttributes = (args: object): string => {
  let argumentList = ''
  Object.entries(args).forEach(([key, value]) => {
    if(typeof value === 'boolean') {
      argumentList += `:${transformKeyFromAttribute(key)}="${transformValueFromAttribute(value)}" `
    } else {
      argumentList += `${transformKeyFromAttribute(key)}="${transformValueFromAttribute(value)}" `
    }
  })

  return argumentList
}

export {
  isJsonString,
  isFunction,
  getPropsFromAttributes,
  transformToAttributes,
  props,
  setEnvFromAttributes
}
