import {Ref, ref} from 'vue'

export default class BrandedComponentLibrary {
    static _instance: BrandedComponentLibrary | null = null
    private env: Ref<'PROD' | 'DEV'> = ref('PROD')

    setEnv(env: 'PROD' | 'DEV') {
      this.env.value = env
    }

    getEnv() {
      return this.env
    }

    static getInstance() {
      if (!BrandedComponentLibrary._instance) {
        BrandedComponentLibrary._instance = new BrandedComponentLibrary()
      }

      return BrandedComponentLibrary._instance
    }
}
