import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ed2f8988"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "brand-footer" }
const _hoisted_2 = { class: "brand-footer-container" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Propvest = _resolveComponent("Propvest")!
  const _component_LocaleSwitch = _resolveComponent("LocaleSwitch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        class: "brand-logo",
        href: _ctx.checkedLogoUrl
      }, [
        _createVNode(_component_Propvest)
      ], 8, _hoisted_3),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (route, index) => {
        return (_openBlock(), _createElementBlock("a", {
          key: index,
          class: "brand-link",
          href: route.url
        }, _toDisplayString(route.title), 9, _hoisted_4))
      }), 128)),
      (_ctx.shouldShowPrivacyLayer)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "brand-link",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openPrivacyLayer && _ctx.openPrivacyLayer(...args)))
          }, _toDisplayString(_ctx.$t('footer.link.cookieSettings')), 1))
        : _createCommentVNode("", true),
      (_ctx.switchLocale)
        ? (_openBlock(), _createBlock(_component_LocaleSwitch, {
            key: 1,
            class: "brand-locale-switch",
            locale: _ctx.currentLocale,
            onClick: _ctx.switchLocale
          }, null, 8, ["locale", "onClick"]))
        : _createCommentVNode("", true)
    ])
  ]))
}