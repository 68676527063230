
import { Options, Prop } from 'vue-property-decorator'
import { Vuei18n } from '../../../i18next/Vuei18n'
import Propvest from '../../../icons/Propvest.vue'
import urls from '../../../urls'
import { Locale } from '../../atoms/localeSwitch/types'
import LocaleSwitch from '../../atoms/localeSwitch/index.vue'

type Route = {
  title: string
  url: string
}

declare namespace UC_UI {
  function showSecondLayer(): void
}

interface UserCentricsHTMLScript extends HTMLScriptElement {
  'data-settings-id'?: string
}

@Options({
  components: {
    Propvest,
    LocaleSwitch
  }
})
export default class Footer extends Vuei18n {
  @Prop({default: undefined}) logoUrl!: string | undefined
  @Prop({default: Locale.DE}) currentLocale!: Locale
  @Prop({default: null}) switchLocale?: (value: Locale) => void
  shouldShowPrivacyLayer: boolean = false

  get routes(): Route[] {
    return  [
      {
        url: urls().imprint,
        title: this.$t('footer.link.imprint')
      },
      {
        url: urls().legal,
        title: this.$t('footer.link.legalNotice')
      },
      {
        url: urls().dataPrivacy,
        title: this.$t('footer.link.dataPrivacy')
      }
    ]
  }

  get checkedLogoUrl(): string | undefined {
    return this.logoUrl || undefined
  }

  mounted() {
    const userCentricsScript: UserCentricsHTMLScript = document.createElement('script')
    userCentricsScript.type = 'text/javascript'
    userCentricsScript.src = 'https://app.usercentrics.eu/browser-ui/latest/loader.js'
    userCentricsScript.async = true
    userCentricsScript.id = 'usercentrics-cmp'
    const dataSettingsIdAttribute = document.createAttribute('data-settings-id')
    dataSettingsIdAttribute.value = 'eoADKgsG2'
    userCentricsScript.attributes.setNamedItem(dataSettingsIdAttribute)

    document.head.appendChild(userCentricsScript)

    let tries = 0
    const intervalId = setInterval(() => {
      const ucExist = typeof UC_UI !== 'undefined'
      if (tries >= 100 || ucExist) {
        this.shouldShowPrivacyLayer = ucExist
        clearInterval(intervalId)
      }

      tries++
    }, 100)
  }

  openPrivacyLayer() {
    if (typeof UC_UI !== 'undefined') {
      UC_UI.showSecondLayer()
    }
  }
}
