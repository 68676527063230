import {Options, Vue} from 'vue-property-decorator'
import i18n from './i18n'
type named = { [key: string]: string }
type options = [defaultTranslation?: string, named?: named] | [named?: named]

const FALLBACK = 'undefined' // replace with undefined to use i18next default

@Options({})
export class Vuei18n extends Vue {
  $t(key: string, defaultTranslation?: string, named?: named): string
  $t(key: string, defaultTranslation: string): string
  $t(key: string, named?: named): string
  $t(key: string): string

  $t(key: string, ...options: options): string {
    if (options && typeof options[0] === 'string')
      return i18n.t(key, options[0], options[1])
    else if (options && typeof options[0] === 'object')
      return i18n.t(key, FALLBACK, options[0])

    return i18n.t(key, FALLBACK)
  }
}