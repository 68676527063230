import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40ea9019"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.size,
    height: _ctx.size,
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M8.12508 9.00002L12.0051 12.88L15.8851 9.00002C16.2751 8.61002 16.9051 8.61002 17.2951 9.00002C17.6851 9.39002 17.6851 10.02 17.2951 10.41L12.7051 15C12.3151 15.39 11.6851 15.39 11.2951 15L6.70508 10.41C6.51783 10.2232 6.4126 9.96954 6.4126 9.70502C6.4126 9.4405 6.51783 9.18685 6.70508 9.00002C7.09508 8.62002 7.73508 8.61002 8.12508 9.00002V9.00002Z",
      fill: _ctx.fill,
      class: _normalizeClass({ fillCurrent: _ctx.fillCurrent })
    }, null, 10, _hoisted_2)
  ], 8, _hoisted_1))
}