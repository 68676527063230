import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23f76042"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "brand-locale-switch" }
const _hoisted_2 = { class: "brand-locale-switch-container" }
const _hoisted_3 = { class: "brand-action-container" }
const _hoisted_4 = { class: "brand-action-text" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "brand-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Globe = _resolveComponent("Globe")!
  const _component_ChevronUp = _resolveComponent("ChevronUp")!
  const _component_ChevronDown = _resolveComponent("ChevronDown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "brand-action",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.switchOptions()))
        }, [
          _createVNode(_component_Globe),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.innerLocale), 1),
          (_ctx.active)
            ? (_openBlock(), _createBlock(_component_ChevronUp, { key: 0 }))
            : _createCommentVNode("", true),
          (!_ctx.active)
            ? (_openBlock(), _createBlock(_component_ChevronDown, { key: 1 }))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["brand-options-menu", [{ 'brand-active': _ctx.active }, `brand-${_ctx.alignment}`]])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(["brand-option", { 'brand-active': _ctx.innerLocale === option.value }]),
            onClick: ($event: any) => (_ctx.handleClick(option.value))
          }, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(option.key), 1)
          ], 10, _hoisted_5))
        }), 128))
      ], 2)
    ])
  ]))
}