import { defineCustomElement as VueDefineCustomElement, h, createApp, getCurrentInstance } from 'vue'
import {Component} from '@vue/runtime-core'
import {isJsonString} from './helper/WebComponentsHelper'
import BrandedComponentLibrary from './urls/BrandedComponentLibrary'

const nearestElement = (el: HTMLElement | null): HTMLElement | null => (
  el?.nodeType !== 1
    ? el?.parentElement ?? null
    : el
)
function getCleanProps(props: { [key: string]: any }) {
  const cleanProps: { [key: string]: any } = {}
  for (const [key, value] of Object.entries(props)) {
    const decodedURI = decodeURI(value)
    if (isJsonString(decodedURI)) {
      cleanProps[key] = JSON.parse(decodedURI)
    } else if (['true', 'false'].includes(value)) {
      cleanProps[key] = Boolean(value)
    } else if (key === 'env') {
      BrandedComponentLibrary.getInstance().setEnv(value ?? 'PROD')
    } else {
      cleanProps[key] = value
    }
  }
  return cleanProps
};


export const defineCustomElement = (component: any, { globalComponents = {}, plugins = [] } = {}) =>
  VueDefineCustomElement({
    props: component.props,
    setup(props) {
      const cleanProps: { [key: string]: any } = getCleanProps(props)

      const app = createApp(component, cleanProps)

      plugins.forEach(app.use)

      Object.entries(globalComponents).forEach(([name, comp]) => app.component(name, comp as Component))

      app.mixin({
        mounted() {
          require('./styles/main.css?modules')
          require('./styles/fonts.css?modules')

          const insertStyles = (styles: string[]) => {
            if (styles?.length) {
              this.__style = document.createElement('style')
              this.__style.innerText = styles.join().replace(/\n/g, '')
              nearestElement(this.$el)?.prepend(this.__style)
            }
          }

          insertStyles(this.$?.type.styles)
          if (this.$options.components) {
            for (const comp of Object.values(this.$options.components)) {
              insertStyles((comp as any).styles)
            }
          }
        },
        unmounted() {
            this.__style?.remove()
        }
      })

      const inst = getCurrentInstance()
      Object.assign(inst?.appContext, app._context)
      return () => h(component, cleanProps)
    }
  })
