import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ec6985e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.size,
    height: _ctx.size,
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M8.12508 15L12.0051 11.12L15.8851 15C16.2751 15.39 16.9051 15.39 17.2951 15C17.6851 14.61 17.6851 13.98 17.2951 13.59L12.7051 8.99998C12.3151 8.60998 11.6851 8.60998 11.2951 8.99998L6.70508 13.59C6.51783 13.7768 6.4126 14.0305 6.4126 14.295C6.4126 14.5595 6.51783 14.8131 6.70508 15C7.09508 15.38 7.73508 15.39 8.12508 15V15Z",
      fill: _ctx.fill,
      class: _normalizeClass({ fillCurrent: _ctx.fillCurrent })
    }, null, 10, _hoisted_2)
  ], 8, _hoisted_1))
}